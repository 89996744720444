<script>
import Stat from "./widget";
import SalesAnalytics from "./sales-analytics";
import Transaction from './transactionadmin';

export default {
  
  components: {
    Stat,
    SalesAnalytics,
    Transaction
  },

  data() {
    return {
    };
  }
};
</script>

<template>
  <div>
    <div class="row">
      <div class="col-12">
        <!--<div class="page-title-box d-flex align-items-center justify-content-between">
          <h4 class="mb-0">HLP - Halim Perdanakusuma Jakarta</h4>
        </div>-->
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <Stat />
        <Transaction />
      </div>
       <div class="col-xl-4">
        <SalesAnalytics />
      </div>
      <div class="col-md-4">
      </div>
    </div>
  </div>
</template>