<script>
import useHttp from '@/comp-functions/useHttp'
import { ref, onMounted } from '@vue/composition-api'
import {formatCurrency} from '@/utils/formatter'
/**
 * Widget Component
 */
export default {
  setup () {
const { $get } = useHttp()

 const selectedOrder = ref({})

const getOrder = async () => {
      const {data} = await $get({ url: 'master/dashboard'})
      selectedOrder.value = data
    }

 onMounted(() => {
      getOrder()
     
    })

     return {
      getOrder,
      selectedOrder,
      formatCurrency
    }

  }
};
</script>

<template>
  <div class="row">

    <!-- begin card -->
    <div class="col-md-3">
      <div class="card">
        <div class="card-body">
          <div class="media">
            <div class="media-body overflow-hidden">
              <p class="text-truncate font-size-14 mb-2">Order</p>
              <h4 class="mb-0 float-left">{{selectedOrder.totalorder}} </h4>
              <h6 class="mt-1 float-left"> &nbsp; ({{selectedOrder.totalcollies}} Collies)</h6>
            </div>
            <div class="text-primary">
              <i class="ri-stack-line"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end card -->

    <!-- begin card -->
    <div class="col-md-3">
      <div class="card">
        <div class="card-body">
          <div class="media">
            <div class="media-body overflow-hidden">
              <p class="text-truncate font-size-14 mb-2">Unpaid</p>
              <h4 class="mb-0">{{formatCurrency(selectedOrder.totalunpaid)}} IDR</h4>
            </div>
            <div class="text-primary">
              <i class="ri-stack-line"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end card -->

    
    <!-- begin card -->
    <div class="col-md-3">
      <div class="card">
        <div class="card-body">
          <div class="media">
            <div class="media-body overflow-hidden">
              <p class="text-truncate font-size-14 mb-2">Incoming</p>
              <h4 class="mb-0">0 Collies</h4>
            </div>
            <div class="text-primary">
              <i class="ri-stack-line"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end card -->

    
    <!-- begin card -->
    <div class="col-md-3">
      <div class="card">
        <div class="card-body">
          <div class="media">
            <div class="media-body overflow-hidden">
              <p class="text-truncate font-size-14 mb-2">Received</p>
              <h4 class="mb-0">0 Collies</h4>
            </div>
            <div class="text-primary">
              <i class="ri-stack-line"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end card -->
  </div>
  <!-- end row -->
</template>