<script>
import useListTable from '@/comp-functions/useListTable';
import {formatNumber} from '@/utils/formatter'
/**
 * Transactions component
 */
export default {

  // computed: {
    /**
     * Total no. of records
     */
  //   rows() {
  //     return this.transactionData.length;
  //   }
  // },
  // mounted() {
  //   // Set the initial number of items
  //   this.totalRows = this.transactionData.length;
  // },
  // methods: {
  //   /**
  //    * Search the table data with search input
  //    */
  //   onFiltered(filteredItems) {
  //     // Trigger pagination to update the number of buttons/pages due to filtering
  //     this.totalList = filteredItems.length;
  //     this.currentPage = 1;
  //   }
  // }

   setup(){
      const tableColumns = [
       { key: "ord_datetime", label: "Order Date", sortable: true },
        { key: "ord_no", label: "Order No.", sortable: true },
        { key: "schedule.vendor.name", label: "Airline", sortable: true },
        { key: "schedule.sch_departure_datetime", label: "Departure", sortable: true },
        { key: "ord_manifestno", label: "SMU No.", sortable: true },
        { key: "ord_weight", label: "Total Weight", sortable: true, tdClass: "text-right",
          formatter: value => {return formatNumber(value)}
        },
        {key: 'ord_gtotalcharge', label: 'Grand Total Charge', tdClass: "text-right",
          formatter: value => {return formatNumber(value)}
        },
        { key: "", label: "Payment Status", thClass: 'text-center', tdClass: 'text-center', sortable: true },
        { key: "", label: "Goods Status", thClass: 'text-center', tdClass: 'text-center', sortable: true },
        // { key: "action", thClass: 'text-center', tdClass: 'text-center', label: "#" }
    ]
    // };
  // },
  // computed: {
  //   /**
  //    * Total no. of records
  //    */
  //   rows() {
  //     return this.transactionData.length;
  //   }
  // },
  // mounted() {
  //   // Set the initial number of items
  //   this.totalRows = this.transactionData.length;
  // },
  // methods: {
    /**
     * Search the table data with search input
     */
    // onFiltered(filteredItems) {
    //   // Trigger pagination to update the number of buttons/pages due to filtering
    //   this.totalRows = filteredItems.length;
    //   this.currentPage = 1;
    // }
  // }
   return {
      tableColumns,
      ...useListTable({
        url: 'master/order'
      }),
    }
  },
};
</script>

<template>
  <div class="card">
    <div class="card-body">
      <h4 class="card-title mb-4">Last 5 Transactions</h4>
      <div class="row mt-4">
        <div class="col-sm-12 col-md-6">
          <div id="tickets-table_length" class="dataTables_length">
            <label class="d-inline-flex align-items-center">
              Show&nbsp;
              <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;entries
            </label>
          </div>
        </div>
        <!-- Search -->
        <!-- <div class="col-sm-12 col-md-6">
          <div id="tickets-table_filter" class="dataTables_filter text-md-right">
            <label class="d-inline-flex align-items-center">
              Search:
              <b-form-input
                v-model="filter"
                type="search"
                class="form-control form-control-sm ml-2"
              ></b-form-input>
            </label>
          </div>
        </div> -->
        <!-- End search -->
      </div>
      <div class="table-responsive">
        <b-table
           :fields="tableColumns"
          responsive="sm"
          :per-page="perPage"
          :current-page="currentPage"
          :items="itemLists"
        >
          <!-- <template v-slot:cell(paymentstatus)="row">
            <div
              class="badge font-size-12"
              :class="{'badge-soft-danger': `${row.value}` === 'Chargeback',
              'badge-soft-success': `${row.value}` === 'Paid',
              'badge-soft-warning': `${row.value}` === 'Unpaid'}"
            >{{ row.value}}</div>
          </template> -->

          <!-- <template v-slot:cell(action)>
            <a
              href="javascript:void(0);"
              class="mr-3 text-primary"
              v-b-tooltip.hover
              data-toggle="tooltip"
              title="Edit"
            >
              <i class="mdi mdi-pencil font-size-18"></i>
            </a>
            <a href="javascript:void(0);" class="text-danger" v-b-tooltip.hover title="Delete">
              <i class="mdi mdi-trash-can font-size-18"></i>
            </a>
          </template> -->
        </b-table>
      </div>
      <div class="row">
        <div class="col">
          <div class="dataTables_paginate paging_simple_numbers float-right">
            <ul class="pagination pagination-rounded mb-0">
              <!-- pagination -->
              <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>