<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import HomeInternal from "./home-internal";
import HomeCustomer from "./home-customer";

export default {
  
  setup () {
    const userLogin = JSON.parse(localStorage.getItem('user'))

    return {
      userLogin
    }
  },

  components: {
    Layout,
    PageHeader,
    HomeInternal,
    HomeCustomer
  },
  data() {
    return {
      title: "Home",
      items: [
        {
          text: "Antar Port"
        },
        {
          text: "Home",
          active: true
        }
      ]
    };
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <HomeInternal v-if="userLogin.usertype === 'UTYINL'"/>
    <HomeCustomer v-if="userLogin.usertype === 'UTYCUS' || userLogin.usertype === 'UTYACS'"/>
  </Layout>
</template>