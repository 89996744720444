<script>
import DatePicker from "vue2-datepicker";
import Multiselect from "vue-multiselect";
import router from "@/router"
import useHttp from '@/comp-functions/useHttp'
import {ref, onMounted} from '@vue/composition-api'
import {formatDatepicker} from '@/utils/formatter'
import Stat from "./widgetcustomer";
import Transaction from './transaction';
import Sources from "./sources";

// import {
//   barChartAirline
// } from "./data-apex";

export default {
  
  components: {
    Multiselect,
    DatePicker,
    Stat,
    Transaction,
    Sources
  },

  setup () {
    const { $get } = useHttp()
    const LOV = ref({
      ports: []
    })
    const valueOrigin = ref()
    const salesAirline = ref({
        series: [{
            data: [5500, 4900, 4570, 4410, 3200]
        }],
        chartOptions: {
            chart: {
                toolbar: {
                    show: false,
                }
            },
            plotOptions: {
                bar: {
                    horizontal: true,
                }
            },
            dataLabels: {
                enabled: false
            },
            colors: ['#34c38f'],
            grid: {
                borderColor: '#f1f1f1',
            },
            xaxis: {
                categories: ['Tri-MG', 'Trigana', 'Air Asia', 'Lion'],
            }
        }
    })
    const valueDestination = ref()
    const valueDeparturedate = ref('')
    
    const searchFlight = () => {
      console.log(valueDeparturedate.value)//.format('DD-MM-YYYY')
      
     // console.log( moment(valueDeparturedate.value).format('DD-MM-YYYY'))//
      router.push({ name: 'apps-order-searchflightparams', params: {origin : valueOrigin.value.prt_initial, destination : valueDestination.value.prt_initial, departure_date : valueDeparturedate.value} })
    }
    // Get account types
    const getPorts = async () => {
      const {data} = await $get({ url: 'master/port'})
      LOV.value.ports = data
    }

   const getAirline = async () => {
      const {data} = await $get({ url: 'master/sales_airline'})
      // debugger
      salesAirline.value.chartOptions.xaxis.categories = data.map(value => value.vnd_name)
      salesAirline.value.series[0] = {
        data: data.map(value => value.totalberat)
      }
    }

    onMounted(() => {
      getPorts()
      getAirline()
     
    })

    return {
      LOV,
      getPorts,
      getAirline,
      searchFlight,
      formatDatepicker,
      salesAirline,
      value1: null,
      valueDeparturedate,
      valueOrigin,
      valueDestination,
      select_label: "Select"
    }
  },
  methods: {
    customLabel ({ prt_initial, prt_locationname }) {
      return `${prt_initial} – ${prt_locationname}`
    },
    disableDate(date){
      const startdate = new Date()
      startdate.setDate(startdate.getDate() - 1);
      return date < startdate;
    }
  }
};
</script>

<template>
  <div>
    <div class="row">
      <div class="col-xl-12">        
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-xl-3">
                <div class="form-group position-relative">
                  <label for="validationTooltipUsername">Origin<span class="text-danger">*</span></label>
                  <multiselect id="mv_origin" name="mv_origin" :select-label="select_label" :custom-label="customLabel" v-model="valueOrigin" :options="LOV.ports"></multiselect>
                </div>
              </div>
              <div class="col-xl-3">
                <div class="form-group position-relative">
                  <label for="validationTooltipUsername">Destination<span class="text-danger">*</span></label>
                  <multiselect id="mv_destination" name="mv_destination" :select-label="select_label" :custom-label="customLabel" v-model="valueDestination" :options="LOV.ports"></multiselect>
                </div>
              </div>

              <div class="col-xl-3">
                <div class="form-group position-relative">
                  <label for="validationTooltipUsername">Departure Date<span class="text-danger">*</span></label>
                  <div class="input-group">
                    <date-picker :first-day-of-week="1" lang="en" v-model="valueDeparturedate" format='DD-MM-YYYY' :disabled-date="disableDate" valueType="format"></date-picker>
                  </div>
                </div>
              </div>
              
              <div class="col-xl-3">
                <div class="form-group position-relative">
                  <label for="validationTooltipUsername"> </label>
                  <div class="input-group">
                    <b-button variant="primary" @click="searchFlight">
                      <i class="ri-search-line"></i>
                      Search Flight
                    </b-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
     <div class="row">
      <div class="col-xl-12">
        <Stat />
      </div>
    </div>
    <div class="row">
      <div class="col-xl-6">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">Top 5 Sales By Airline</h4>
            <!-- Bar Chart -->
            <apexchart
              class="apex-charts"
              height="350"
              type="bar"
              dir="ltr"
              :series="salesAirline.series"
              :options="salesAirline.chartOptions"
            ></apexchart>
          </div>
        </div>
      </div>
      <div class="col-xl-6">
        <Sources />
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <Transaction />
      </div>
    </div>
    
    
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-lg-10">
                For additional requests from available schedules for extra flights or charter flights. <br/>
                Please contact us on WA or click the following link :
              </div>
              <div class="col-lg-2 text-right">
                <a href="https://api.whatsapp.com/send/?phone=6281319230767&text=Hi+Antarport%2C&app_absent=0" >
                  <span>
                    <img src="@/assets/images/whatsapp.png" alt height="50" />
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>